<template>
  <layout title="Professores" :options="options" :rotas="rotas">
    <SalesAvulse v-if="tabs == 1" />
    <WithdrawAvulse v-if="tabs == 2" />
    <SellerAvulse v-if="tabs == 3 && getLoggedUser.is_supervisor" />
    <v-bottom-navigation height="70px" background-color="#fff" class="elevation-0" fixed bottom>
      <v-btn 
        :value="menu.name"
        v-for="(menu, index) in menus"
        :key="`item-menu-${index+1}`"
        dark
        color="transparent"
        text
        @click="menu.action2"
        v-show="menu.permission()"
        class="px-6 mx-3"
        :id="`item-menu-${index+1}`"
      >
        <span :class="{ 'font-weight-bold': tabs == index+1 }" class="deep-purple--text fonte mt-2">{{ menu.name }}</span>
        <v-chip style="height: 26px;" :color="tabs == index+1 ? 'deep-purple lighten-5' : '#fff'">
          <v-icon size="22" :color="tabs == index+1 ? 'deep-purple' : 'deep-purple'">{{ menu.icon }}</v-icon>
        </v-chip>
      </v-btn>
    </v-bottom-navigation>
  </layout>
</template>

<script>
import SellerAvulse from "@/apps/Seller/views/Avulse.vue";
import SalesAvulse from "@/apps/Sales/views/Avulse.vue";
import WithdrawAvulse from "@/apps/BalanceReport/views/Avulse.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    SellerAvulse,
    SalesAvulse,
    WithdrawAvulse,
  },
  data() {
    return {
      options: [],
      rotas: [
        {
          name: "Início",
          path: "/inicio-admin"
        },
      ],
      tabs: 1,
      menus: [
        {
          icon: "mdi-storefront-plus-outline",
          name: "Comissões",
          action2: () => this.tabs = 1,
          permission: () => true
        },
        {
          icon: "mdi-cash-multiple",
          name: "Saques",
          action2: () => this.tabs = 2,
          permission: () => true
        },
        {
          icon: "mdi-account-group-outline",
          name: "Equipe",
          action2: () => this.tabs = 3,
          permission: () => this.getLoggedUser.is_supervisor
        },
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getLoggedUser"
    ])
  },
  mounted() {
    document.getElementById("item-menu-0").click();
    this.tabs = 1
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 96vh;
  max-height: 96vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
.v-btn:before {
  background-color: #fff !important;
  background: #fff !important;
}
.v-btn:active {
  background-color: #fff !important;
  background: #fff !important;
}
</style>
