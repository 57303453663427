<template>
  <v-sheet
    :dark="$theme.dark"
    :light="$theme.light"
    class="expande-horizontal sheet-container pt-3"
  >
    <v-flex xs12 class="pr-3" lg3>
      <Filters :filter="filter" />
      <List :filtereds="filtereds" />
      <EmptyList
        :iniciarCadastro="iniciarCadastro"
        v-if="filtereds.length === 0"
      />
    </v-flex>
    <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
      <ViewForm v-if="get_teacher.new || get_teacher._id" />
    </v-flex>
    <v-dialog
      v-else
      transition="slide-x-transition"
      fullscreen
      :value="get_teacher._id || get_teacher.new ? true : false"
    >
      <v-card>
        <ViewForm />
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import EmptyList from "../components/EmptyList.vue";
import Filters from "../components/Filters.vue";
import List from "../components/List.vue";
import ViewForm from "../components/ViewForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      rotas: [
        {
          name: "Início",
          path: "/inicio"
        },
        {
          name: "Minha Equipe",
          path: "/vendedores"
        }
      ]
    };
  },
  components: {
    EmptyList,
    Filters,
    List,
    ViewForm
  },
  computed: {
    ...mapGetters([
      "get_teachers_filtros",
      "get_permissions_filters",
      "getLoggedUser",
      "get_teacher",
      "get_teachers",
      "getPersonalizedLoading"
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_teachers.docs.filter(item => {
          return (
            (item.nome &&
              item.nome
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.email &&
              item.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.telefone &&
              item.telefone.includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_teachers.docs;
      }
    }
  },
  methods: {
    excluir_teacher_dialog(item) {
      let text = "Deseja remover este teacher?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_teacher",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_teachers",
      "criar_estoque",
      "listar_teachercategorias",
      "criar_teacher",
      "list_permissions",
      "listar_estoques",
      "atualizar_teacher",
      "setPersonalizedLoading",
      "createConfirmAction",
      "listar_insumos"
    ]),
    criarOuAtualizar() {
      if (this.get_teacher._id) {
        this.atualizar_teacher();
      } else {
        this.criar_teacher();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_teacher", {
        new: true,
        address: {},
        professional_record: {}
      });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_teacher._id) {
          this.atualizar_teacher();
        } else {
          this.criar_teacher();
        }
      }
    }
  },
  created() {
    this.get_permissions_filters.all = true;
    this.list_permissions();
    this.listar_teachers();
    this.listar_insumos();
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 96vh;
  max-height: 96vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
