<template>
  <v-sheet
    :dark="$theme.dark"
    :light="$theme.light"
    class="expande-horizontal sheet-container pt-2"
  >
    <v-flex xs12 class="pr-1" lg3>
      <Filters :filter="filter" />
      <v-list class="fonte">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte">{{ $helper.formataSaldo(balance) }}</v-list-item-title>
            <v-list-item-subtitle class="fonte">Meu Saldo</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </v-list>
      <List :filtereds="filtereds" />
      <EmptyList
        :iniciarCadastro="iniciarCadastro"
        v-if="filtereds.length === 0"
      />
    </v-flex>
    <v-flex v-if="$vuetify.breakpoint.lgAndUp" xs12 md9 lg9>
      <ViewForm v-if="get_withdraw.new || get_withdraw._id" />
    </v-flex>
    <v-dialog
      v-else
      transition="slide-x-transition"
      fullscreen
      :value="get_withdraw._id || get_withdraw.new ? true : false"
    >
      <v-card>
        <ViewForm />
      </v-card>
    </v-dialog>
    <v-btn
      color="grey darken-3"
      absolute
      bottom
      right
      fab
      @click="iniciarCadastro"
      style="position: fixed; border-radius: 12px;margin-bottom: 120px;"
    >
      <v-icon size="21" color="#fff">mdi-plus</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
import EmptyList from "../components/EmptyList.vue";
import Filters from "../components/Filters.vue";
import List from "../components/List.vue";
import ViewForm from "../components/ViewForm.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      balance: 0,
      options: [
        {
          nome: "Novo saque",
          action: this.iniciarCadastro,
          icon: "mdi-plus"
        }
      ],
      rotas: [
        {
          name: "Início",
          path: "/dashboard"
        },
        {
          name: "saques",
          path: "/saques"
        }
      ]
    };
  },
  components: {
    EmptyList,
    Filters,
    List,
    ViewForm
  },
  computed: {
    ...mapGetters([
      "get_withdraws_filtros",
      "getLoggedUser",
      "get_withdraw",
      "get_withdraws",
      "getPersonalizedLoading"
    ]),
    filtereds() {
      if (this.filter.search) {
        return this.get_withdraws.docs.filter(item => {
          return (
            (item.nome &&
              item.nome
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.email &&
              item.email
                .toLowerCase()
                .includes(this.filter.search.toLowerCase())) ||
            (item.telefone &&
              item.telefone.includes(this.filter.search.toLowerCase()))
          );
        });
      } else {
        return this.get_withdraws.docs;
      }
    }
  },
  methods: {
    excluir_withdraw_dialog(item) {
      let text = "Deseja remover este withdraw?";
      this.createConfirmAction({
        message: text,
        icon: "mdi-close-circle",
        action: "excluir_withdraw",
        action_value: item
      });
    },
    getLinkById(id) {
      return this.getLinkById[id];
    },
    ...mapActions([
      "abre_modal_view_tenant",
      "listar_tenants",
      "listar_withdraws",
      "criar_estoque",
      "listar_withdrawcategorias",
      "criar_withdraw",
      "listar_estoques",
      "atualizar_withdraw",
      "setPersonalizedLoading",
      "createConfirmAction"
    ]),
    criarOuAtualizar() {
      if (this.get_withdraw._id) {
        this.atualizar_withdraw();
      } else {
        this.criar_withdraw();
      }
    },
    iniciarCadastro() {
      this.setPersonalizedLoading("animate__fadeOutTopRight");
      this.$store.commit("set_withdraw", { new: true });
      this.$store.commit("setLinks", []);
      this.setPersonalizedLoading("animate__fadeInUp");
    },
    createOrUpdate() {
      if (this.$refs.form.validate()) {
        if (this.get_withdraw._id) {
          this.atualizar_withdraw();
        } else {
          this.criar_withdraw();
        }
      }
    },
    viewMyBalance() {
      this.$run("balance-report/view-my-balance")
        .then(res => {
          this.balance = res.data.balance;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.listar_withdraws();
    this.viewMyBalance();
  }
};
</script>

<style scoped>
.sheet-container {
  min-height: 95vh;
  max-height: 95vh;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.item-list {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #1173ff;
}
</style>
