<template>
  <v-list
    class="pa-0 ma-0 fonte pt-1"
    dense
    :dark="$theme.dark"
    :light="$theme.light"
    background-color="transparent"
    style="min-height: 20vh; max-height: 90vh; overflow: auto;"
  >
    <template v-for="teacher in filtereds">
      <v-list-item
        @click="open(teacher)"
        style="border-radius: 6px;"
        :key="teacher._id"
        class="fonte pl-1 pr-1"
      >
        <v-list-item-content class="ml-1">
          <v-list-item-title>
            <span class="fonte">
              {{ teacher.nome }}
            </span>
          </v-list-item-title>
          <!-- <v-list-item-subtitle class="fonteMini">
            <v-chip
              x-small
              color="#e5e5e5"
              :style="`color: ${$theme.primary}; border-radius: 3px;`"
            >
              <v-icon color="#333" size="11" class="mr-2"
                >mdi-card-account-details-outline</v-icon
              >
              {{ teacher.cpf }}
            </v-chip>
          </v-list-item-subtitle> -->
          <v-list-item-subtitle>
            <span class="fonteMini">
              {{ teacher.email }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar rounded size="36" color="#e5e5e5">
            <v-icon :color="$theme.primary">mdi-account-circle-outline</v-icon>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    filtereds: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(["get_teacher"])
  },
  methods: {
    open(payload) {
      let data = payload;
      if (!data.professional_record) {
        data.professional_record = {
          advice: "",
          number: "",
          uf: ""
        };
      }
      if (!data.address) {  
        data.address = {
          street: "",
          number: "",
          complement: "",
          neighborhood: "",
          city: "",
          state: "",
          zipcode: ""
        };
      }
      this.$store.commit("set_teacher", data);
    }
  }
};
</script>
